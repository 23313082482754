/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mj-calendar-date__num"
};
var _hoisted_2 = {
  class: "mj-calendar-date__text"
};
export default {
  __name: 'MJCalendarDate',
  props: {
    num: [String, Number],
    text: String,
    isToday: Boolean
  },
  setup: function setup(__props) {
    var props = __props;
    var store = useStore();
    var cData = reactive({});
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["mj-calendar-date", {
          'mj-calendar-date_hilight': __props.isToday
        }])
      }, [_createElementVNode("div", _hoisted_1, _toDisplayString(__props.num), 1), _createElementVNode("div", _hoisted_2, "(" + _toDisplayString(__props.text) + ")", 1)], 2);
    };
  }
};